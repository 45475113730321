*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html, body {
  scroll-behavior: smooth;
  background: #02232f;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #2d995b;
}

::-webkit-scrollbar-thumb {
  background: #2d995b;
  border-radius: 5px;
}

.secondary-color {
  background-color: #011c28;
}

.tertiary-color {
  background-color: #02232f;
}

.fourthary-color {
  background-color: #48484887;
}

.text-green {
  color: #2d995b;
}

.text-blue {
  color: #00bbdf;
}

ul li a .icon {
  text-align: center;
  color: #fff;
  font-size: 1.5em;
  line-height: 75px;
  transition: all .5s;
  display: block;
  position: relative;
}

ul li a .icon-text {
  color: #fff;
  letter-spacing: .05em;
  opacity: 0;
  font-size: .85em;
  font-weight: 800;
  transition: all .5s;
  position: absolute;
  transform: translateY(20px);
}

ul li.active a .icon {
  transform: translateY(-35px);
}

ul li.active a .icon-text {
  opacity: 1;
  transform: translateY(10px);
}

.indicator {
  background: #2d995b;
  border: 6px solid #02232f;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  transition: all .5s;
  position: absolute;
  top: -50%;
}

.indicator:before {
  content: "";
  background: none;
  border-top-right-radius: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: -22px;
  box-shadow: 1px -10px #02232f;
}

.indicator:after {
  content: "";
  background: none;
  border-top-left-radius: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: -22px;
  box-shadow: -1px -10px #02232f;
}

li:first-child.active ~ .indicator {
  transform: translateX(0);
}

li:nth-child(2).active ~ .indicator {
  transform: translateX(72px);
}

li:nth-child(3).active ~ .indicator {
  transform: translateX(144px);
}

li:nth-child(4).active ~ .indicator {
  transform: translateX(216px);
}

.item-1 {
  --i: 0;
}

.item-2 {
  --i: 1;
}

.item-3 {
  --i: 2;
}

.item-4 {
  --i: 3;
}

.item-5 {
  --i: 4;
}

.item-6 {
  --i: 5;
}

.item-7 {
  --i: 6;
}

.menu-icons {
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  display: flex;
  position: absolute;
  right: 10px;
  transform: rotate(280deg);
}

.menu-icons .circle-menu-icons {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  transition: all .5s;
  display: flex;
  position: absolute;
  box-shadow: 0 0 0 68px #ffffff73;
}

.menu-icons li {
  transform-origin: 165px;
  transform: rotate(calc(180deg / 7 * var(--i))) translate(55px);
  z-index: 10;
  font-size: .7em;
  list-style: none;
  transition: all .5s;
  position: absolute;
  left: 90px;
}

.menu-icons li a {
  color: #fff;
  transform: rotate(calc(180deg / 28 * var(--i)));
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  font-size: 1.75em;
  display: flex;
}

.menu-icons li.active {
  transform: rotate(calc(180deg / 7 * var(--i))) translate(15px);
}

.indicator-technology {
  transform-origin: 100%;
  pointer-events: none;
  background: none;
  width: 100px;
  height: 1px;
  transition: all .5s;
  position: absolute;
  top: 250px;
  right: 250px;
}

.indicator-technology:before {
  content: "";
  background: #2d995b;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  transition: all .5s;
  position: absolute;
  bottom: -27.5px;
  right: 95px;
  box-shadow: 0 0 0 6px #02232f;
}

.indicator-technology-box {
  transform-origin: 100%;
  pointer-events: none;
  background: none;
  width: 100px;
  height: 1px;
  transition: all .5s;
  position: absolute;
  top: 250px;
  right: 250px;
}

.indicator-technology-box:before {
  content: attr(data-text);
  background: #ffffff73;
  border-radius: 20px;
  width: 350px;
  height: 55px;
  padding: 8px;
  transition: all .5s;
  position: absolute;
  bottom: 240.5px;
  right: -5px;
  rotate: 80deg;
}

.menu-icons li:nth-child(2).active ~ .indicator-technology {
  transform: translateX(0)rotate(0);
}

.menu-icons li:nth-child(3).active ~ .indicator-technology {
  transform: translateX(0)rotate(27.7deg);
}

.menu-icons li:nth-child(4).active ~ .indicator-technology {
  transform: translateX(-3px)rotate(55deg);
}

.menu-icons li:nth-child(5).active ~ .indicator-technology {
  transform: translateX(-2px)rotate(80.1deg);
}

.menu-icons li:nth-child(6).active ~ .indicator-technology {
  transform: translateX(0)rotate(105.8deg);
}

.menu-icons li:nth-child(7).active ~ .indicator-technology {
  transform: translateX(0)rotate(131.5deg);
}

.menu-icons li:nth-child(8).active ~ .indicator-technology {
  transform: translateX(0)rotate(156deg);
}

.menu-icons li:nth-child(2).active ~ .indicator-technology-box {
  transform: translateX(0)rotate(0);
}

.menu-icons li:nth-child(3).active ~ .indicator-technology-box {
  transform: translateY(-55px)translateX(16px)rotate(-.3deg);
}

.menu-icons li:nth-child(4).active ~ .indicator-technology-box {
  transform: translateY(-85px)translateX(53px)rotate(-.3deg);
}

.menu-icons li:nth-child(5).active ~ .indicator-technology-box {
  transform: translateY(-100px)translateX(103px)rotate(-.3deg);
}

.menu-icons li:nth-child(6).active ~ .indicator-technology-box {
  transform: translateY(-100px)translateX(158px)rotate(-.3deg);
}

.menu-icons li:nth-child(7).active ~ .indicator-technology-box {
  transform: translateY(-80px)translateX(206px)rotate(-.3deg);
}

.menu-icons li:nth-child(8).active ~ .indicator-technology-box {
  transform: translateY(-80px)translateX(230px)rotate(-.3deg);
}

.refName:after {
  content: "";
  border: 50px solid #0000;
  border-bottom-color: #011c28;
  display: block;
  position: relative;
  bottom: -68px;
  right: -65px;
  transform: rotate(135deg);
  box-shadow: 0 7px 6px -8px #000;
}

.refName:before {
  content: "";
  border: 50px solid #0000;
  border-top-color: #011c28;
  display: block;
  position: relative;
  top: -62px;
  left: -65px;
  transform: rotate(135deg);
  box-shadow: 0 -7px 6px -8px #000;
}

@font-face {
  font-family: Yellowtail;
  src: url("Yellowtail-Regular.182f2394.ttf") format("truetype");
  font-display: swap;
}

.fonts {
  font-family: Yellowtail, Arial, Helvetica, sans-serif;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-36 {
  bottom: -9rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-8 {
  bottom: 2rem;
}

.right-2 {
  right: .5rem;
}

.right-7 {
  right: 1.75rem;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 1.25rem;
}

.m-\[2px\] {
  margin: 2px;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-40 {
  height: 10rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[420px\] {
  height: 420px;
}

.h-\[65\%\] {
  height: 65%;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.w-24 {
  width: 6rem;
}

.w-3\/5 {
  width: 60%;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[60\%\] {
  width: 60%;
}

.w-full {
  width: 100%;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-6 {
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[99\%\] {
  --tw-scale-x: 99%;
  --tw-scale-y: 99%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-\[\#2d995b\] {
  --tw-border-opacity: 1;
  border-color: rgb(45 153 91 / var(--tw-border-opacity));
}

.border-\[\#7a797933\] {
  border-color: #7a797933;
}

.bg-\[\#011c289a\] {
  background-color: #011c289a;
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.object-contain {
  object-fit: contain;
}

.p-10 {
  padding: 2.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[2px\] {
  padding: 2px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-\[\#2d995b\] {
  --tw-text-opacity: 1;
  color: rgb(45 153 91 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-50 {
  opacity: .5;
}

.shadow-\[0px_0_29px_7px_\#090a09\] {
  --tw-shadow: 0px 0 29px 7px #090a09;
  --tw-shadow-colored: 0px 0 29px 7px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.blur-3xl {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[101\%\]:hover {
  --tw-scale-x: 101%;
  --tw-scale-y: 101%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.hover\:animate-bounce:hover {
  animation: 1s infinite bounce;
}

.hover\:animate-pulse:hover {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.hover\:animate-spin:hover {
  animation: 1s linear infinite spin;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-l-8:hover {
  border-left-width: 8px;
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.hover\:text-\[\#27834f\]:hover {
  --tw-text-opacity: 1;
  color: rgb(39 131 79 / var(--tw-text-opacity));
}

.hover\:text-\[\#2d995b\]:hover {
  --tw-text-opacity: 1;
  color: rgb(45 153 91 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group:hover .group-hover\:rotate-6 {
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.group:hover .group-hover\:text-\[\#2d995b\] {
  --tw-text-opacity: 1;
  color: rgb(45 153 91 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:right-0 {
    right: 0;
  }

  .sm\:p-28 {
    padding: 7rem;
  }

  .sm\:pl-28 {
    padding-left: 7rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pr-14 {
    padding-right: 3.5rem;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:pr-9 {
    padding-right: 2.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[55\%\] {
    width: 55%;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }
}

@media (width >= 1024px) {
  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-\[55\%\] {
    width: 55%;
  }

  .lg\:w-\[60\%\] {
    width: 60%;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pl-28 {
    padding-left: 7rem;
  }

  .lg\:pr-28 {
    padding-right: 7rem;
  }
}
/*# sourceMappingURL=index.89f7dd2e.css.map */
