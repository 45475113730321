@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,body{
        scroll-behavior: smooth;
        background: #02232f;
        overflow-x: hidden;
    }

    /* width */

    ::-webkit-scrollbar {
        width: 20px;
    }
    
    /* Track */
    
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #2d995b;
    }
    
    /* Handle */
    
    ::-webkit-scrollbar-thumb {
        background: #2d995b;
        border-radius: 5px;
    }

    .hide{
        display: none;
    }

    .primary-color{
        background-color: #2d995b;
    }
    .secondary-color{
        background-color: #011c28;
    }
    .tertiary-color{
        background-color: #02232f;
    }
    .fourthary-color{
        background-color: #48484887;
    }
    
    .text-green{
        color: #2d995b;
    }

    .text-blue{
       color: #00bbdf;
    }
   
    ul li a .icon{
        position: relative;
        display: block;
        line-height: 75px;
        font-size: 1.5em;
        text-align: center;
        transition: 0.5s;
        color: white;
    }

    ul li a .icon-text{
        position: absolute;
        color: white;
        font-weight: 800 ;
        font-size: 0.85em;
        letter-spacing: 0.05em;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(20px);
    }

    ul li.active a .icon{
        transform: translateY(-35px);
    }

    ul li.active a .icon-text{
        opacity: 1;
        transform: translateY(10px);
    }

    .indicator{
        position: absolute;
        top: -50%;
        width: 70px;
        height: 70px;
        background: #2d995b;
        border-radius: 50%;
        border: 6px solid #02232f;
        transition: 0.5s;
    }

    .indicator::before{
        content: '';
        position: absolute;
        top: 50%;
        left: -22px;
        width: 20px;
        height: 20px;
        background: transparent;
        border-top-right-radius: 20px;
        box-shadow: 1px -10px 0 0 #02232f;
    }

    .indicator::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -22px;
        width: 20px;
        height: 20px;
        background: transparent;
        border-top-left-radius: 20px;
        box-shadow: -1px -10px 0 0 #02232f;
    }
    
    .icon-inactive{
        display: none;
    }

    li:nth-child(1).active ~ .indicator{
        transform: translateX(calc(72px * 0));
    }
    li:nth-child(2).active ~ .indicator{
        transform: translateX(calc(72px * 1));
    }
    li:nth-child(3).active ~ .indicator{
        transform: translateX(calc(72px * 2));
    }
    li:nth-child(4).active ~ .indicator{
        transform: translateX(calc(72px * 3));
    }

   
    .item-1{
        --i:0;
    }
    .item-2{
        --i:1;
    }
    .item-3{
        --i:2;
    }
    .item-4{
        --i:3;
    }
    .item-5{
        --i:4;
    }
    .item-6{
        --i:5;
    }
    .item-7{
        --i:6;
    }

    .menu-icons{
        position: absolute;
        right: 10px;
        width: 500px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(280deg);
    }
    
    .menu-icons .circle-menu-icons{
        position: absolute;
        width: 95px;
        height: 95px;
        border-radius: 50%;
        box-shadow: 0 0 0 68px rgba(255, 255, 255, 0.45);
    
    
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    

    .menu-icons li{
        font-size: 0.7em;
        list-style: none;
        position: absolute;
        left: 90px;
        transform-origin: 165px;
        transform: rotate(calc(180deg/7 * var(--i))) translate(55px);
        z-index: 10;
        transition: 0.5s;
    }
    
    .menu-icons li a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 55px;
        font-size: 1.75em;
        color: white;
        transform: rotate(calc(180deg/28 * var(--i)));
        border-radius: 50%;
    }
    
    .menu-icons li.active{
        transform: rotate(calc(180deg/7 * var(--i))) translate(15px);
    }
    
    .indicator-technology{
        position: absolute;
        top: 250px;
        right: 250px;
        transform-origin: right;
        width: 100px;
        height: 1px;
        
        background: transparent;
        pointer-events: none;
        transition: 0.5s;
    }
    .indicator-technology::before{
        content: '';
        position: absolute;
        bottom: -27.5px;
        right: 95px;
        width: 55px;
        height: 55px;
        background: #2d995b ;
        box-shadow: 0 0 0 6px #02232f;
        border-radius: 50%;
        transition: 0.5s;
    }

    .indicator-technology-box{
        position: absolute;
        top: 250px;
        right: 250px;
        transform-origin: right;
        width: 100px;
        height: 1px;
        
        background: transparent;
        pointer-events: none;
        transition: 0.5s;
    }

    .indicator-technology-box::before{
        content: attr(data-text);
        padding: 8px;
        width: 350px;
        height: 55px;
        background: #ffffff73;
        border-radius: 20px;
        transition: all .5s;
        position: absolute;
        bottom: 240.5px;
        right: -5px;
        rotate: 80deg;
    }

    .menu-icons li:nth-child(2).active ~ .indicator-technology{
        transform: translateX(0px) rotate(0deg);
    }
    .menu-icons li:nth-child(3).active ~ .indicator-technology{
        transform: translateX(0px) rotate(27.7deg);
    }
    .menu-icons li:nth-child(4).active ~ .indicator-technology{
        transform: translateX(-3px) rotate(55deg);
    }
    .menu-icons li:nth-child(5).active ~ .indicator-technology{
        transform: translateX(-2px) rotate(80.1deg);
    }
    .menu-icons li:nth-child(6).active ~ .indicator-technology{
        transform: translateX(-0px) rotate(105.8deg);
    }
    .menu-icons li:nth-child(7).active ~ .indicator-technology{
        transform: translateX(-0px) rotate(131.5deg);
    }
    .menu-icons li:nth-child(8).active ~ .indicator-technology{
        transform: translateX(-0px) rotate(156deg);
    }
   
    .menu-icons li:nth-child(2).active ~ .indicator-technology-box{
        transform: translateX(0) rotate(0deg);
    }
    .menu-icons li:nth-child(3).active ~ .indicator-technology-box{
        transform: translateY(-55px)translateX(16px)rotate(-0.3deg);
    }
    .menu-icons li:nth-child(4).active ~ .indicator-technology-box{
        transform: translateY(-85px)translateX(53px)rotate(-.3deg);
    }
    .menu-icons li:nth-child(5).active ~ .indicator-technology-box{
        transform: translateY(-100px)translateX(103px)rotate(-.3deg);
    }
    .menu-icons li:nth-child(6).active ~ .indicator-technology-box{
        transform: translateY(-100px)translateX(158px)rotate(-.3deg);
    }
    .menu-icons li:nth-child(7).active ~ .indicator-technology-box{
        transform: translateY(-80px)translateX(206px)rotate(-.3deg);
    }
    .menu-icons li:nth-child(8).active ~ .indicator-technology-box{
        transform: translateY(-80px)translateX(230px)rotate(-.3deg);
    }
   
    .refName::after{
        
        content: "";
        display: block;
        position: relative;
        border: 50px solid transparent;
        border-bottom: 50px solid #011c28;
        bottom: -68px;
        right: -65px;
        box-shadow: 0px 7px 6px -8px black;
        transform: rotate(135deg);

    }

    .refName::before {
        content: "";
        display: block;
        position: relative;
        border: 50px solid transparent;
        border-top: 50px solid #011c28;
        top: -62px;
        left: -65px;
        box-shadow: 0px -7px 6px -8px black;
        transform: rotate(135deg);
      }

      @font-face {
        font-family: "Yellowtail";
        src: url('./fonts/Yellowtail-Regular.ttf') format('truetype');
        font-display: swap;
      }
      
      .fonts{
        font-family: "Yellowtail", Arial, Helvetica, sans-serif;
      }

  }